<template>
  <ion-page>
    <ion-content>
      <ion-tabs>
        <ion-router-outlet :key="Date.now()"></ion-router-outlet>

        <ion-tab-bar slot="bottom">
          <ion-tab-button tab="motori" href="/motori">
            <ion-icon :icon="cogOutline" />
            <ion-label>Motori</ion-label>
          </ion-tab-button>

          <ion-tab-button tab="interventi" href="/interventi">
            <ion-icon :icon="calendarOutline" />
            <ion-label>Interventi</ion-label>
          </ion-tab-button>

          <ion-tab-button tab="documenti" href="/documenti">
            <ion-icon :icon="documentAttachOutline" />
            <ion-label>Documenti</ion-label>
          </ion-tab-button>

          <ion-tab-button tab="contatti" href="/contatti">
            <ion-icon :icon="callOutline" />
            <ion-label>Contatti</ion-label>
          </ion-tab-button>
        </ion-tab-bar>
      </ion-tabs>
    </ion-content>
  </ion-page>
  <!-- </ion-page> -->
</template>

<script>
import { defineComponent } from "vue";
import { useStore } from "vuex";
import { mapGetters } from "vuex";
import {
  IonContent,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonLabel,
  IonIcon,
  IonPage,
  IonRouterOutlet,
} from "@ionic/vue";
import {
  ellipse,
  square,
  triangle,
  powerOutline,
  cogOutline,
  documentTextOutline,
  documentAttachOutline,
  callOutline,
  mapOutline,
  calendarOutline,
  golfOutline,
  exitOutline,
} from "ionicons/icons";

export default defineComponent({
  name: "SedePage",
  components: {
    IonContent,
    IonLabel,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonPage,
    IonRouterOutlet,
  },
  setup() {
    return {
      ellipse,
      square,
      triangle,
      powerOutline,
      cogOutline,
      documentTextOutline,
      documentAttachOutline,
      callOutline,
      mapOutline,
      calendarOutline,
      golfOutline,
      exitOutline,
    };
  },
  computed: {
    ...mapGetters({
      getUser: "user",
      getSede: "sede",
    }),
  },
  data: () => ({
    store: useStore(),
  }),
});
</script>

<style>
ion-menu::part(container) {
  border-radius: 0 20px 20px 0;
}
</style>
