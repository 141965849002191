<template>
  <ion-page>
    <ion-content class="login_container">
      <div class="login_bg_mask">
        <div
          class="login_bg"
          style="background-image: url('/assets/images/banner_login.jpg')"
        >
          <ion-img src="/assets/images/ab_logo.svg" style="width: 100px"></ion-img>
        </div>
      </div>
      <ion-grid v-if="this.stat == false">
        <ion-row>
          <ion-col>
            <ion-text color="primary" class="ion-margin-horizontal ion-text-center">
              <h6 class="login_des">Inserire i nuovi dati</h6>
            </ion-text>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-progress-bar v-if="loader" type="indeterminate"></ion-progress-bar>
            <ion-list>
              <form name="ab_login" @submit.prevent="my_new_psw">
                <ion-item>
                  <ion-label color="primary" position="floating">Email</ion-label>
                  <ion-icon
                    color="primary"
                    :icon="personOutline"
                    slot="start"
                    class="ion-align-self-center"
                    size="small"
                  >
                  </ion-icon>
                  <ion-input
                    v-model="user.email"
                    autocorrect="on"
                    autocomplete="on"
                    autofocus="on"
                    color="primary"
                    inputmode="text"
                    placeholder="Enter your email"
                    required="true"
                  ></ion-input>
                </ion-item>
                <ion-item>
                  <ion-label color="primary" position="floating">
                    Nuova Password</ion-label
                  >
                  <ion-icon
                    color="primary"
                    :icon="lockClosedOutline"
                    slot="start"
                    class="ion-align-self-center"
                    size="small"
                  >
                  </ion-icon>
                  <ion-input
                    v-model="user.password"
                    :type="showPsw ? 'text' : 'password'"
                    autofocus="on"
                    autocomplete="on"
                    color="primary"
                    placeholder="Enter new password"
                    required="true"
                  ></ion-input>
                  <ion-button
                    slot="end"
                    class="ion-align-self-end togglePsw"
                    fill="outline"
                    @click="showPassword"
                  >
                    <ion-icon
                      :icon="showPsw ? eyeOutline : eyeOffOutline"
                      v-if="user.password"
                      size="small"
                    ></ion-icon>
                  </ion-button>
                </ion-item>
                <ion-item>
                  <ion-label color="primary" position="floating"> Token</ion-label>
                  <ion-icon
                    color="primary"
                    :icon="lockClosedOutline"
                    slot="start"
                    class="ion-align-self-center"
                    size="small"
                  >
                  </ion-icon>
                  <ion-input
                    v-model="user.token"
                    placeholder="Enter your reset token"
                    required="true"
                  ></ion-input>
                </ion-item>
                <ion-row>
                  <ion-col>
                    <ion-button type="submit" class="ion-margin-vertical" expand="full"
                      >RESET</ion-button
                    >
                  </ion-col>
                </ion-row>
              </form>
            </ion-list>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-gird v-else>
        <ion-row>
          <ion-col>
            <h6 class="ion-text-center">Password resettata con successo !!</h6>
            <h6 class="ion-text-center">
              Attendi ti stiamo reindirizzando alla pagina della login.
            </h6>
          </ion-col>
        </ion-row>
      </ion-gird>

      <!--ALERT POP UP-->
      <ion-alert
        :is-open="isOpenRef"
        :header="error_header"
        :sub-header="error_sub"
        :message="error_text"
        :buttons="['OK']"
        @didDismiss="setOpen(false)"
      ></ion-alert>
      <!--ALERT POP UP-->
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonInput,
  IonButton,
  IonImg,
  IonText,
  IonLabel,
  IonAlert,
  IonProgressBar,
  IonList,
  IonItem,
} from "@ionic/vue";
import { ref } from "vue";
import {
  personOutline,
  lockClosedOutline,
  eyeOffOutline,
  eyeOutline,
} from "ionicons/icons";
import { useStore } from "vuex";
import { mapGetters, mapState } from "vuex";
import { useRouter } from "vue-router";
import axios from "axios";
import GoogleLogin from "@/components/GoogleLogin.vue";
// import { authService } from "@/api";

export default {
  name: "LoginPage",
  components: {
    IonPage,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonIcon,
    IonInput,
    IonButton,
    IonImg,
    IonText,
    IonLabel,
    IonAlert,
    IonProgressBar,
    // GoogleLogin,
    IonList,
    IonItem,
  },
  setup() {
    const router = useRouter();
    const isOpenRef = ref(false);
    const setOpen = (state) => (isOpenRef.value = state);
    return {
      router,
      personOutline,
      lockClosedOutline,
      eyeOffOutline,
      eyeOutline,
      isOpenRef,
      setOpen,
    };
  },
  mounted() {
    console.log(this.$route.query.otp);
    this.otp_get();
  },
  data: () => ({
    loader: false,
    store: useStore(),
    user: {
      email: null,
      token: null,
      password: null,
    },
    error_header: "Attenzione",
    error_sub: "",
    error_text: "",
    showPsw: false,
    stat: false,
  }),
  computed: {
    ...mapState(["user"]),

    ...mapGetters({
      getUser: "user",
    }),
  },
  methods: {
    otp_get() {
      let otp = this.$route.query.otp;
      this.user.token = otp;
      console.log(otp);
    },
    showPassword() {
      this.showPsw = !this.showPsw;
    },

    showMsg(msg) {
      this.error_text = msg;
      this.setOpen(true);
    },

    my_new_psw() {
      this.loader = true;
      this.showPsw = false;
      var self = this;
      axios
        .post(`${process.env.VUE_APP_AUTH_URL}/newpassword`, this.user)
        .then((res) => {
          let token = res.data.token;
          console.log(res.data);
          self.loader = false;
          this.stat = true;
          if (this.stat == true) {
            setTimeout(() => {
              window.location.href = "/login";
            }, 2000);
          }
        })
        .catch(function (error) {
          // let status = error.toJSON().status;
          // switch(status){
          //   case 404: self.error_text = 'Utente non trovato';
          //   break;
          //   case 401: self.error_text = 'Email o password mancante';
          //   break;
          //   default : self.error_text = 'ops, qualcosa è andato storto';
          // }
          self.loader = false;
          let message_err = error.response.data.message;
          let generic_err = error.message;
          console.log(message_err);
          console.log(generic_err);

          message_err ? (self.error_text = message_err) : (self.error_text = generic_err);

          self.isOpenMsg = true;

          self.setOpen(true);
        });
    },
  },
};
</script>

<style lang="scss">
.underline {
  text-decoration: underline;
}

.login_bg_mask {
  /* width: 400px;  */
  height: 300px !important;
}

.login_bg {
  padding: 20px;
  height: 100% !important;
  background-color: black;
  background-size: cover;
  background-position: center;
  background-size: cover;
  transition: all 0.5s;
}

.login_des {
  padding: 0 50px;
}

.togglePsw {
  --border-style: none;
  margin-left: 5px;
  // --border-radius: 0;
}

.cursor_pointer {
  cursor: pointer;
}
</style>
