<template>
  <ion-page>
    <ion-progress-bar v-if="loader" type="indeterminate"></ion-progress-bar>
    <ion-content>
      <ion-refresher slot="fixed" :pull-factor="0.5" :pull-min="100" :pull-max="200" @ionRefresh="handleRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <ion-grid>
        <ion-row>
          <ion-col class="ion-padding-vertical">
            <ion-title class="main_title">
              Sedi Collegate
              <ion-icon class="ion-float-right" :icon="cogOutline"></ion-icon>
            </ion-title>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-card v-for="(country, name) in country_list" :key="name" width="100%">
            <ion-card-header color="primary">
              <ion-card-title>
                {{ name }}
                <ion-icon class="ion-float-right" :icon="mapOutline"></ion-icon>
              </ion-card-title>
            </ion-card-header>

            <ion-card-content>
              <ion-list>
                <ion-item v-for="(regione, index) in country" :key="index">
                  <ion-label>{{ index }}</ion-label>

                  <ion-button class="ion-float-right" @click="goToArea(regione, index)">VEDI SEDE</ion-button>
                </ion-item>
              </ion-list>
            </ion-card-content>
          </ion-card>
        </ion-row>
      </ion-grid>
      <!--ALERT POP UP-->
      <ion-alert :is-open="isOpenRef" :header="error_header" :sub-header="error_sub" :message="error_text"
        :buttons="['OK']" @didDismiss="setOpen(false)"></ion-alert>
      <!--ALERT POP UP-->
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";
import { mapGetters, mapState } from "vuex";
// import { countryCods } from "@/utils/countries";
import {
  IonIcon,
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonAlert,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonList,
  IonItem,
  IonLabel,
  IonCardTitle,
  IonButton,
  IonProgressBar,
} from "@ionic/vue";
import {
  ellipse,
  square,
  triangle,
  powerOutline,
  mapOutline,
  golfOutline,
  exitOutline
} from "ionicons/icons";
import axios from "axios";
import _ from "lodash";



export default defineComponent({
  name: "SediPage",
  components: {
    IonIcon,
    IonPage,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonAlert,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonRefresher,
    IonRefresherContent,
    IonTitle,
    IonList,
    IonItem,
    IonLabel,
    IonCardTitle,
    IonButton,
    IonProgressBar
  },
  setup() {
    const isOpenRef = ref(false);
    const setOpen = (state) => (isOpenRef.value = state);
    return {
      ellipse,
      square,
      triangle,
      powerOutline,
      mapOutline,
      golfOutline,
      exitOutline,
      isOpenRef,
      setOpen,
    };
  },
  data: () => ({
    loader: true,
    store: useStore(),
    country_list: {},
    error_header: "Alert!",
    error_sub: "",
    error_text: "",
  }),
  mounted() {

    this.getData().then((res) => {
      if (res) {
        this.getLettura();
      }
    });
  },

  computed: {
    ...mapState({
      getUser: "user",
      getSede: "sede",
      token: "token",
    }),
    // ...mapGetters({
    //   getUser: "user",
    //   getSede: "sede",
    //   token: "token",
    // }),
  },
  methods: {

    getData() {
      return new Promise((resolve, reject) => {
        var self = this;

        let config = {
          headers: {
            token: localStorage.getItem("access_token"),
          },
          withCredentials: true,
        };


        axios
          .get(`${process.env.VUE_APP_AUTH_URL}/registry`, config)
          .then((res) => {

            var country_list = {};

            if (res.data) {
              //order res data by cod_module

              res.data.result = _.orderBy(res.data.result, ["cod_module"], ["asc"]);

              res.data.result.forEach((k, i) => {
                let iso_cod = k.country;
                let region = k.des_sap_plant_name;

                //pack coord in gps array for map link
                if (k["des_latitude"] && k["des_longitude"]) {
                  k["gps"] = [k.des_latitude, k.des_longitude];
                }

                delete k["des_latitude"];
                delete k["des_longitude"];

                if (iso_cod in country_list) {
                  if (region in country_list[iso_cod]) {
                    country_list[iso_cod][region].push(k);
                  } else {
                    country_list[iso_cod][region] = [];

                    country_list[iso_cod][region].push(k);
                  }
                } else {
                  country_list[iso_cod] = {};

                  country_list[iso_cod][region] = [];

                  country_list[iso_cod][region].push(k);
                }
              });

              this.country_list = country_list;

              resolve(true);

            } else {
              self.error_text = "Nessuna sede trovata";
              self.setOpen(true);
              resolve(false);
            }
          })
          .catch(function (error) {
            self.error_text = error.response.data.message ?? "ops, qualcosa è andato storto";
            self.setOpen(true);
            resolve(false);
          });

      });//end promise
    },

    getLettura() {

      let config = {
        headers: {
          token: localStorage.getItem("access_token"),
        },
        withCredentials: true,
      };

      let lettura_group = [];

      //leggere lettura impianto
      axios.get(`${process.env.VUE_APP_AUTH_URL}/lettureImpianto`, config).then((res) => {

        res.data.result.forEach((lettura, i) => {
          if (lettura_group[lettura.cod_impianto] == undefined) {
            lettura_group[lettura.cod_impianto] = [];
          }

          lettura_group[lettura.cod_impianto].push(lettura);

          for (const [nazione, regione] of Object.entries(this.country_list)) {

            for (const [index, paese] of Object.entries(regione)) {

              paese.forEach(sede => {
                
                //assign lettura if lettura
                let lettura = lettura_group[sede.cod_engine] ? lettura_group[sede.cod_engine][0] : null;
                
                sede['lettura'] = lettura;

              });

              


            }
          }

          this.loader = false;

        });
      });

    },

    goToArea(regione, index) {

      this.store.dispatch("actionA", { sede: regione }).then((res) => {
        if (res) {
          this.$router.push(`sedi/${index}`);
        }
      });

    },
    handleRefresh(event) {
      setTimeout(() => {
        this.getData;
        event.target.complete();
      }, 1000);
    },
  },
});
</script>

<style>
.welcome_bg_mask {
  /* width: 400px;  */
  height: 100px !important;
}

.welcome_bg {
  padding: 20px;
  height: 100% !important;
  background-color: black;
  background-size: cover;
  background-position: center;
  background-size: cover;
  transition: all 0.5s;
}

.main_title {
  /* padding: 20px; */
  font-weight: bold;
  /* text-transform: uppercase; */
  color: red;
}

ion-card {
  width: 100% !important;
}
</style>
