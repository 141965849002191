<template>
  <ion-page>
    <ion-menu content-id="main-content">
      <ion-header>
        <ion-toolbar color="primary">
          <ion-title>Menu</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list lines="full">
          <ion-item v-if="this.$route.name != 'sedi'">
            <ion-label router-link="/sedi">Le mie Sedi
              <ion-icon class="ion-float-right" :icon="golfOutline"></ion-icon></ion-label>
          </ion-item>
          <ion-item>
            <ion-label @click="handleLogout()">Logout <ion-icon class="ion-float-right"
                :icon="exitOutline"></ion-icon></ion-label>
          </ion-item>
        </ion-list>
      </ion-content>
    </ion-menu>
    <ion-page id="main-content">
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start" v-if="this.$route.name != 'motori' && this.$route.name != 'sedi'">
            <ion-back-button defaultHref="/motori"></ion-back-button>
          </ion-buttons>
          <ion-buttons slot="end">
            <ion-menu-button></ion-menu-button>
          </ion-buttons>
        </ion-toolbar>
        <div class="welcome_bg_mask">
          <div class="welcome_bg" style="background-image: url('/assets/images/banner_login.jpg')">
            <ion-img src="/assets/images/ab_logo.svg" style="width: 100px"></ion-img>
          </div>
        </div>
      </ion-header>

      <ion-content>
        <ion-router-outlet :key="Date.now()"></ion-router-outlet>
      </ion-content>
    </ion-page>
  </ion-page>
</template>

<script>
import { useStore } from "vuex";
import { mapGetters, mapState } from "vuex";
import { defineComponent, ref } from "vue";
import { useAuth } from "../config/useAuth";
const {logout} = useAuth();
import {
  IonIcon,
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonBackButton,
  IonMenu,
  IonMenuButton,
  IonTitle,
  IonList,
  IonItem,
  IonLabel,
  IonButtons,
  IonImg,
  IonRouterOutlet,
  menuController,
} from "@ionic/vue";
import { golfOutline, exitOutline, reload, logoWindows } from "ionicons/icons";
import router from "@/router";

export default defineComponent({
  name: "MainContainer",
  components: {
    IonIcon,
    IonPage,
    IonContent,
    IonHeader,
    IonToolbar,
    IonBackButton,
    IonMenu,
    IonMenuButton,
    IonTitle,
    IonList,
    IonItem,
    IonLabel,
    IonButtons,
    IonImg,
    IonRouterOutlet,
  },
  setup() {
    const isOpenRef = ref(false);

    const setOpen = (state) => (isOpenRef.value = state);
    
    const handleLogout = async () => {
      await logout();
    };
    
    return {
      golfOutline,
      exitOutline,
      isOpenRef,
      setOpen,
      handleLogout,
    };
  },
  data: () => ({
    store: useStore(),
  }),
  mounted() { },
  computed: {
    ...mapState(["user"]),

    ...mapGetters({
      getUser: "user",
    }),
  },
  watch:{
    $route (to, from){
        //close drawer
        menuController.close();
    }
},
  methods: {
    // logout() {
    //   this.store.commit("logoutUserState");
    //   router.push("/login");
    //   window.localStorage.removeItem("VUEX-PERSISTEDSTATE");
    // },
  },
});
</script>
