<template>
  <ion-page>
    <ion-content class="login_container">
      <div class="login_bg_mask">
        <div
          class="login_bg"
          style="background-image: url('/assets/images/banner_login.jpg')"
        >
          <ion-img src="/assets/images/ab_logo.svg" style="width: 100px"></ion-img>
        </div>
      </div>
      <ion-grid>
        <ion-row>
          <ion-col>
            <ion-progress-bar v-if="loader" type="indeterminate"></ion-progress-bar>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-text color="primary" class="ion-margin-horizontal ion-text-center">
              <h6 class="login_des">Accedi all'app</h6>
            </ion-text>
          </ion-col>
        </ion-row>
        
        <ion-row>
          <ion-col class="ion-margin-vertical ion-text-center ion-text-underlined">
            <ion-button @click="AzureLogin()" class="ion-margin-vertical" expand="full" size="xl">ACCEDI</ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>

      <!--ALERT POP UP-->
      <ion-alert
        :is-open="isOpenRef"
        :header="error_header"
        :sub-header="error_sub"
        :message="error_text"
        :buttons="['OK']"
        @didDismiss="setOpen(false)"
      ></ion-alert>
      <!--ALERT POP UP-->
    </ion-content>
  </ion-page>
</template>

<script>
import { useAuth } from "../config/useAuth";
// import { myMSALObj, state } from "../config/msalConfig";

const {login, logout, handleRedirect, getAccount} = useAuth();
import { myMSALObj } from "@/config/msalConfig";
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonInput,
  IonButton,
  IonImg,
  IonText,
  IonLabel,
  IonAlert,
  IonProgressBar,
  IonList,
  IonItem,
  IonCheckbox,
} from "@ionic/vue";
import { ref } from "vue";
import {
  personOutline,
  lockClosedOutline,
  eyeOffOutline,
  eyeOutline,
} from "ionicons/icons";
import { useStore } from "vuex";
import { mapGetters, mapState } from "vuex";
import { useRouter } from "vue-router";
import axios from "axios";
// import { authService } from "@/api";

export default {
  name: "LoginPage",
  components: {
    IonPage,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    // IonIcon,
    // IonInput,
    // IonButton,
    IonImg,
    IonText,
    // IonLabel,
    IonAlert,
    IonProgressBar,
    // GoogleLogin,
    // IonList,
    // IonItem,
    // IonCheckbox,
  },
  setup() {
    const router = useRouter();
    const isOpenRef = ref(false);
    const setOpen = (state) => (isOpenRef.value = state);

    const initialize = async () =>{
      try{
        await myMSALObj.initialize(); // call init
      }catch(error){
        //disable sso button
        console.log('init error',error);
      }
    }

    // const handleLogin = async () =>{
    // await login().then((res)=>{return res}).catch((error)=>{
    //   console.log('error',error);}
    // )};

    
    return {
      router,
      personOutline,
      lockClosedOutline,
      eyeOffOutline,
      eyeOutline,
      isOpenRef,
      setOpen,
      initialize,
      login
    };


  },
  async mounted() {

    // if(this.getUser.remember){
    //   this.remember_me = this.getUser.remember;
    //   this.$router.push("/sedi");
    // }
    await this.initialize();
    
    let is_redirect = this.$route.hash ?? false;
    if (is_redirect) {
      await this.AzureLogin();
    }

  },
  data: () => ({
    loader: false,
    remember_me: false,
    store: useStore(),
    user: {
      email: null,
      password: null,
    },
    error_header: "Attenzione",
    error_sub: "",
    error_text: "",
    showPsw: false,
  }),
  computed: {
    ...mapState(["user"]),

    ...mapGetters({
      getUser: "user",
    }),
  },
  methods: {
    showPassword() {
      this.showPsw = !this.showPsw;
    },

    showMsg(msg) {
      this.error_text = msg;
      this.setOpen(true);
    },
    async AzureLogin(){
      var self = this;
      await this.login().then((res)=>{
        console.log('res',res);
        if(localStorage.getItem("access_token")){
          let token = localStorage.getItem("access_token");
          let config = {
            headers: {
              token: token,
            },
            withCredentials: true,
          };
          axios.get(`${process.env.VUE_APP_AUTH_URL}/me`, config)
          .then((res) => {
            // let authorized = res.data.authorized;
            // let logged_in = res.data.logged_in;
            // res.data.remember = this.remember_me;
            let authorized = true;
            let logged_in = true;
            res.data.remember = this.remember_me;
            if (authorized) {
              this.store.commit("setUser", {
                state: authorized,
                user: res.data,
              });
              self.loader = false;
              if (this.getUser != {}) {
                this.$router.push("/sedi");
              }
            } else {
              if (logged_in && !authorized) {

                self.loader = false;
                // self.error_text = `La tua richiesta di registrazione è stata presa in carico. Riceverai conferma dell'abilitazione all'indirizzo ${res.data.email}`;

                self.$router.push("/thank-you");

              } else {

                self.loader = false;
                self.error_text = "ops, qualcosa è andato storto";
              
              }
              //generic error
              self.loader = false;

              self.setOpen(true);
            }
          }).catch(function (error) {
            //generic error
            //this.loader = false;
            //self.error_text = "ops, qualcosa è andato storto";

            if(error.response.status == 404){

              self.$router.push("/thank-you");

              //self.error_text = "Utente non autorizzato, verrai contattato quando l'autorizzazione sarà completata";
            }
            //self.setOpen(true);
            console.log(error.toJSON().status);
          });
        }
      }); 
    },
    // login() {
    //   this.loader = true;
    //   this.showPsw = false;
    //   var self = this;
    //   axios
    //     .post(`${process.env.VUE_APP_AUTH_URL}/login`, this.user)
    //     .then((res) => {
    //       let token = res.data.token;

    //       if (token) {
    //         localStorage.setItem("access_token", token);
    //         this.store.commit("setToken", { token: token });

    //         let config = {
    //           headers: {
    //             token: token,
    //           },
    //           withCredentials: true,
    //         };

    //         axios
    //           .get(`${process.env.VUE_APP_AUTH_URL}/me`, config)
    //           .then((res) => {
    //             let authorized = res.data.authorized;
    //             let logged_in = res.data.logged_in;
    //             res.data.remember = this.remember_me;
    //             if (authorized) {
    //               this.store.commit("setUser", {
    //                 state: authorized,
    //                 user: res.data,
    //               });

    //               self.loader = false;

    //               if (this.getUser != {}) {

    //                 this.$router.push("/sedi");
                  
    //               }
    //             } else {
    //               if (logged_in && !authorized) {
    //                 self.loader = false;
    //                 self.error_text = `La tua richiesta di registrazione è stata presa in carico. Riceverai conferma dell'abilitazione all'indirizzo ${res.data.email}`;
    //               } else {
    //                 self.loader = false;
    //                 self.error_text = "ops, qualcosa è andato storto";
    //               }

    //               //generic error
    //               self.loader = false;
    //               self.setOpen(true);
    //             }
    //           })
    //           .catch(function (error) {
    //             //generic error
    //             self.loader = false;
    //             self.error_text = "ops, qualcosa è andato storto";
    //             self.setOpen(true);
    //             console.log(error.toJSON().status);
    //           });
    //       }
    //     })
    //     .catch(function (error) {
    //       // let status = error.toJSON().status;
    //       // switch(status){
    //       //   case 404: self.error_text = 'Utente non trovato';
    //       //   break;
    //       //   case 401: self.error_text = 'Email o password mancante';
    //       //   break;
    //       //   default : self.error_text = 'ops, qualcosa è andato storto';
    //       // }
    //       self.loader = false;
    //       let message_err = error.response.data.message;
    //       let generic_err = error.message;

    //       message_err ? (self.error_text = message_err) : (self.error_text = generic_err);

    //       self.isOpenMsg = true;

    //       self.setOpen(true);
    //     });
    // },
  },
  
};
</script>

<style lang="scss">
.underline {
  text-decoration: underline;
}

.login_bg_mask {
  /* width: 400px;  */
  height: 300px !important;
}

.login_bg {
  padding: 20px;
  height: 100% !important;
  background-color: black;
  background-size: cover;
  background-position: center;
  background-size: cover;
  transition: all 0.5s;
}

.login_des {
  padding: 0 50px;
}

.togglePsw {
  --border-style: none;
  margin-left: 5px;
  // --border-radius: 0;
}

.cursor_pointer {
  cursor: pointer;
}
.my_pl {
  padding-left: 1rem;
}
</style>
