import { authService } from "@/api";
import { createStore, useStore} from 'vuex';
import moment from 'moment';
//VUEX-PERSISTEDSTATE
import createPersistedState from "vuex-plugin-persistedstate";


const state = {
  user: {},
  isLoggedIn: false,
  remember:false,
  token: null,
  sede: null,
  motore:null,
  storico_analisi:null,
  analisi:null,
};

//GETTERS
const getters = {
  isLoggedIn: (state) => state.isLoggedIn,
  sede: (state) => state.sede,
  user: (state) => state.user,
  motore: (state) => state.motore,
  storico_analisi: (state) => state.storico_analisi,
  analisi: (state) => state.analisi,
  token: (state) => state.token,
};

//SETTERS
const mutations = {
  

  setUser(st, data) {
    state.isLoggedIn = data.user.authorized;
    state.token = data.user.token;
    state.user = data.user;
    state.remember = data.remember;
  },

  setToken(st, data) {
    state.token = data.token;
  },
  
  setSede(st, data) {
    //console.log(data);
    state.sede = data.sede;
  },
  
  setMotore(st, data) {
    state.motore = data.motore;
  },

  setAnalisi(st, data) {
    //sample_date
    // let format = window.navigator.language == 'it-IT' ? 'DD-MM-YYYY' : 'YYYY-MM-DD';
    // if(data.analisi){
    //   data.analisi.sample_date = moment(data.analisi.sample_date).format(format);
    //   console.log(data.analisi.sample_date);
    // }
    state.analisi = data.analisi;
  },

  setStoricoAnalisi(st, data) {
    //sample_date
    // let format = window.navigator.language == 'it-IT' ? 'DD-MM-YYYY' : 'YYYY-MM-DD';
    // console.log(data);
    // if(data.storico_analisi){
    //   data.storico_analisi.forEach(analisi => {
    //     analisi.sample_date = moment(analisi.sample_date).format(format);
    //   });

    // }
    state.storico_analisi = data.storico_analisi;
  },
  

  logoutUserState(state) {
    state.isLoggedIn = false;
    state.user = {};
    localStorage.removeItem('VUEX-PERSISTEDSTATE');
    localStorage.removeItem('access_token');
    sessionStorage.clear();
    localStorage.clear();
    console.log('logoutUserState');

  },
  
};


//METHODS
const actions = {

  actionA ({commit}, payload) {
    return new Promise((resolve, reject) => {
        //console.log(payload);
        commit('setSede',payload)
        resolve(true)
    })
  },
  
  async registerUser({ dispatch },user) {
    
    var msg = '';
    await authService.post("/signin", user).then(async (res) => {
      
      msg = res.data.message;
      
    
    });

  
  },

  async fetchUser({ commit }) {
    await authService.get("/user").then(({ data }) => commit("setUser", data));
  },

  //
  async logoutUser({ commit }) {
    await authService.post("/logout");
    commit("logoutUserState");
  },
};

//EXPORT

const store = createStore({
  strict: true,
  state : state,
  actions:actions,
  getters:getters,
  mutations: {
    // RESTORE_MUTATION: mutations.restore, //<DEPRECATED>
    ...mutations}, 
  // plugins: [vuexLocal.plugin]
  plugins: [createPersistedState()],
});

export default store;
