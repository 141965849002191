import { PublicClientApplication, AccountInfo, RedirectRequest, LogLevel } from '@azure/msal-browser'
import { reactive } from 'vue';

const tenant = process.env.VUE_APP_AZURE_TENTANT;
const clientId = process.env.VUE_APP_AZURE_CLIENT_ID;
const clientIdSandbox = process.env.VUE_APP_AZURE_CLIENT_ID_SANDBOX;
const localRedirectUri = process.env.VUE_APP_SPA_AZURE_REDIRECT_URI;
const sandboxRedirectUri = process.env.VUE_APP_SPA_AZURE_REDIRECT_URI_SANDBOX;
const nativeRedirectUri = process.env.VUE_APP_SPA_AZURE_NATIVE_REDIRECT_URI;
const susi_policy = process.env.VUE_APP_AZURE_SUSI_POLICY;

let dynamicRedirectUri = window.location.hostname == 'localhost' ? localRedirectUri : sandboxRedirectUri;
let dynamicClientId = window.location.hostname == 'localhost' ? clientId : clientIdSandbox;

export const msalConfig = {
  auth: {
    clientId: `${dynamicClientId}`,
    authority: `https://${tenant}.b2clogin.com/${tenant}.onmicrosoft.com/${susi_policy}`,
    knownAuthorities: [`${tenant}.b2clogin.com`], // array of URIs that are known to be valid,
    validateAuthority: true,
    //authority: 'https://login.microsoftonline.com/common', // common endpoint for generic azure login
    redirectUri: `${dynamicRedirectUri}`,
    //scope:`https://gruppoabb2c.onmicrosoft.com/${clientId}/user_impersonation`,
    response_type:'code'
  },
  postLogoutUri: "http://localhost:5000/logout",
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
        logLevel: LogLevel.Trace,
        loggerCallback: (level, message, containsPii) => {
            if (containsPii) {
                return;
            }
            switch (level) {
                case LogLevel.Error:
                    console.error(message);
                    return;
                case LogLevel.Info:
                    console.info(message);
                    return;
                case LogLevel.Verbose:
                    console.debug(message);
                    return;
                case LogLevel.Warning:
                    console.warn(message);
                    return;
                default:
                    console.log(message);
                    return;
            }
        }
    }
},
}

export const graphScopes = {
  scopes: ['user.read', 'openid', 'profile'],
}

export const state = reactive({
  isAuthenticated: false,
  user: null,
})

export const myMSALObj = new PublicClientApplication(msalConfig)
