<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <!-- <ion-buttons slot="start">
          <ion-back-button defaultHref="/"></ion-back-button>
        </ion-buttons> -->
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-grid>
        <ion-row>
          <ion-col>
            <div class="login_container">
              <div class="login_bg_mask">
                <div
                  class="login_bg"
                  style="background-image: url('/assets/images/banner_login.jpg')"
                >
                  <ion-img
                    src="/assets/images/ab_logo.svg"
                    style="width: 100px"
                  ></ion-img>
                </div>
              </div>
              <ion-row>
                <ion-col>
                  <ion-text color="primary" class="ion-margin-horizontal ion-text-center">
                    <h3 class="login_des">Grazie <br> <span style="text-transform: capitalize;"> {{ tmp_name }}</span></h3>
                    <br />
                    <h6 class="login_des">
                      Abbiamo ricevuto la tua richiesta di accesso. <br> Riceverai notifica all'indirizzo <br> <span style="text-decoration: underline;">{{ tmp_email }}</span> <br> appena il tuo account sarà attivo.
                    </h6>
                  </ion-text>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col>
                  <ion-button @click="handleLogout()" class="ion-margin-vertical" expand="full" size="xl">ACCEDI DA ACCOUNT DIVERSO</ion-button>
                </ion-col>
              </ion-row>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonText,
  IonHeader,
  // IonToolbar,
  // IonButtons,
  // IonBackButton,
} from "@ionic/vue";
// import { personOutline, lockClosedOutline, mailOutline } from 'ionicons/icons';
import { useRouter } from "vue-router";
import { useAuth } from "../config/useAuth";
const {logout} = useAuth();
export default {
  name: "RegisterPage",
  components: {
    IonPage,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    // IonButtons,
    IonImg,
    IonText,
    IonHeader,
    // IonToolbar,
    // IonBackButton,
  },
  setup() {
    const router = useRouter();

    const handleLogout = async () => {
      await logout();
    };

    return {
      router,
      handleLogout,
    };
  },
  data: () => ({
    tmp_name: localStorage.getItem("tmp_name") || "",
    tmp_email: localStorage.getItem("tmp_email") || "",
  }),
  mounted() {
    // setTimeout(() => {
    //   this.$router.push("/login");
    // }, 2000);
  },
  computed: {

  },
  methods: {},
};
</script>

<style>
.underline {
  text-decoration: underline;
}

.login_bg_mask {
  /* width: 400px;  */
  height: 300px !important;
}

.login_bg {
  padding: 20px;
  height: 100% !important;
  background-color: black;
  background-size: cover;
  background-position: center;
  background-size: cover;
  transition: all 0.5s;
}

.login_des {
  padding: 0 50px;
}
</style>
