import { ref } from "vue";
import { useStore } from "vuex";
import { msalConfig, myMSALObj, state } from "./msalConfig";

export function useAuth() {
  const isAuthenticated = ref(false);

  // const login = async () => {
  //   myMSALObj
  //     .handleRedirectPromise()
  //     .then((tokenResponse) => {
  //       if (!tokenResponse) {
  //         const accounts = myMSALObj.getAllAccounts();
  //         if (accounts.length === 0) {
  //           // No user signed in
  //           console.log("No user signed in");

  //           myMSALObj.loginRedirect().then((tokenResponse)=>{

  //             localStorage.setItem("access_token", tokenResponse.idToken);
  //             return tokenResponse;

  //           });
  //         }
  //       } else {
  //         localStorage.setItem("access_token",tokenResponse.idToken);
  //         return tokenResponse;
  //       }
  //     })
  //     .catch((err) => {
  //       // Handle error
  //       console.log(err);
  //       return err;
  //       //alert(err);
  //     });
    
  //     //

  // };

  // const login = async () => {
  //   try {
  //     const tokenResponse = await myMSALObj.handleRedirectPromise();
  //     if (!tokenResponse) {
  //       const accounts = myMSALObj.getAllAccounts();
  //       if (accounts.length === 0) {
  //         console.log("No user signed in");

  //         const loginResponse = await myMSALObj.loginRedirect();
          
  //         localStorage.setItem("access_token", loginResponse.idToken);
  //         //myMSALObj.setActiveAccount(loginResponse.account);
  //         return loginResponse;
  //       }
  //     } else {
  //       localStorage.setItem("access_token", tokenResponse.idToken);
  //       //myMSALObj.setActiveAccount(tokenResponse.account);
  //       return tokenResponse;
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  //We are having trouble signing you in. Please try again later.

  //updated but not tested
  // const login = async () => {
  //   try {
  //     const accounts = myMSALObj.getAllAccounts();
  //     if (accounts.length > 0) {
  //       console.log("User already logged in.");
  //       return;
  //     }
  
  //     const tokenResponse = await myMSALObj.handleRedirectPromise();
  //     if (!tokenResponse) {
  //       console.log("No user signed in");
  //       const loginResponse = await myMSALObj.loginRedirect();
  //       // Store the token in local storage
  //       localStorage.setItem("access_token", loginResponse.idToken);
  //       return loginResponse;
  //     } else {
  //       // Store the token in local storage
  //       localStorage.setItem("access_token", tokenResponse.idToken);
  //       return tokenResponse;
  //     }
  //   } catch (error) {
  //     console.error("Failed to log in", error);
  //     throw error;
  //   }
  // };

  const login = async () => {
    try {
      const accounts = myMSALObj.getAllAccounts();
      if (accounts.length > 0) {
        console.log("User already logged in.");
        return;
      }
    
      const tokenResponse = await myMSALObj.handleRedirectPromise();
      if (!tokenResponse) {
        console.log("No user signed in");
        await myMSALObj.loginRedirect();
      } else {
        // Store the token in local storage
        localStorage.setItem("access_token", tokenResponse.idToken);
        console.log(tokenResponse);
        // Store tmp_email in local storage
        if (tokenResponse.account) {
          localStorage.setItem("tmp_email", tokenResponse.account.username);
          localStorage.setItem("tmp_name", tokenResponse.account.name);
        }
        return tokenResponse;
      }
    } catch (error) {
      console.error("Failed to log in", error);
      throw error;
    }
    
    // Handle redirect after login
    myMSALObj.handleRedirectPromise()
      .then((loginResponse) => {
        // Store the token in local storage
        localStorage.setItem("access_token", loginResponse.idToken);
        // Store tmp_email in local storage
        if (loginResponse.account) {
          localStorage.setItem("tmp_email", loginResponse.account.username);
          localStorage.setItem("tmp_name", loginResponse.account.name);
        }
        return loginResponse;
      })
      .catch((error) => {
        console.error("Failed to handle redirect", error);
        throw error;
      });
    };

  const logout = async () => {
    if (!myMSALObj) {
      throw new Error(
        "MSAL not initialized. Call initializeMsal() before using MSAL AΡΙ."
      );
    }

    const logoutRequest = {
      account: myMSALObj.getActiveAccount(),
      postLogoutRedirectUri: window.location.origin + "/logout",
    };

    myMSALObj.logoutRedirect(logoutRequest).then(() => {
      //
    });

    // myMSALObj.logoutRedirect().then((res) => {
    //   useStore.commit("logoutUserState");
    //   // router.push("/login");
    //   window.localStorage.removeItem("VUEX-PERSISTEDSTATE");
    //   return res;
    // });
    // isAuthenticated.value = false;
    // console.log("Logged out");
  };

  const handleRedirect = async () => {
    try {
      await myMSALObj.handleRedirectPromise();
      state.isAuthenticated = myMSALObj.getAllAccounts().length > 0;
      state.user = myMSALObj.getAllAccounts()[0];
    } catch (error) {
      console.error("Redirect error:", error);
    }
  };

  const getAccount = async () => {
    var request = {
      scopes: ["user.read", "openid", "profile"],
    };

    myMSALObj
      .acquireTokenSilent({ scopes: ["user.read"] })
      .then((tokenResponse) => {
        localStorage.setItem("access_token",tokenResponse.idToken);
          return tokenResponse.idToken;
      })
      .catch(async (error) => {
        if (error) {
          // fallback to interaction when silent call fails
          alert(error);
        }

        // handle other errors
      });
  };

  const acquireToken = async () => {
    const accounts = myMSALObj.getAllAccounts();
    if (accounts.length > 0) {
      try {
        const response = await myMSALObj.acquireTokenSilent({
          account: accounts[0],
          scopes: ["User.Read"],
        });
        
        localStorage.setItem("access_token",response.idToken);
        return response.idToken;
      } catch (error) {
        console.error("Token acquisition failed:", error);
      }
    }
  };

  return { isAuthenticated, login, logout, handleRedirect, getAccount, acquireToken };
}
