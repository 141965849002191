import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { IonicVue } from "@ionic/vue";

// import { createAnimation } from '@ionic/vue';

/*Vuex State management */
import store from "./store/modules/ab";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import { defineCustomElements } from "@ionic/pwa-elements/loader";

//internationalization
import i18n from "./i18n";

defineCustomElements(window);


/*EXAMPLE: Page animation*/

// const myAnimation = (baseEl, opts) => {
//   const { enteringEl, leavingEl } = opts;

//   const enteringPage = createAnimation('entering-page-animation')
//     .addElement(enteringEl)
//     .fromTo('opacity', 1, 0);

//   const leavingPage = createAnimation('leaving-page-animation')
//     .addElement(leavingEl)
//     .fromTo('opacity', 0, 1);

//   return createAnimation('root-transition')
//     .duration(2000)
//     .easing('ease-in-out')
//     .addAnimation([enteringPage, leavingPage]);
// }

const app = createApp({
  extends: App,
  // beforeCreate() { store.commit('loadStore') }
})
  .use(i18n)
  .use(IonicVue, {
    rippleEffect: false,
    mode: "md",
    animated: true,
    // navAnimation: myAnimation,
    swipeBackEnabled: true,
  })
  .use(store)
  .use(router);

router.isReady().then(() => {
  app.mount("#app");
});
