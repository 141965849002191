<template>
  <ion-page>
    <ion-content>
      <ion-grid>
        <div class="login_container">
          <div class="login_bg_mask">
            <div
              class="login_bg"
              style="background-image: url('/assets/images/banner_login.jpg')"
            >
              <ion-img src="/assets/images/ab_logo.svg" style="width: 100px"></ion-img>
            </div>
          </div>
          <ion-progress-bar v-if="loader" type="indeterminate"></ion-progress-bar>
          <ion-row>
            <ion-col>
              <ion-text color="primary" class="ion-margin-horizontal ion-text-center">
                <h6 class="login_des">Richiesta di registrazione</h6>
              </ion-text>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-list>
                <form action="#" @submit.prevent="register">
                  <ion-item>
                    <ion-label color="primary" position="floating">Nome</ion-label>
                    <ion-icon
                      color="primary"
                      :icon="personOutline"
                      slot="start"
                      class="ion-align-self-center"
                      size="small"
                    >
                    </ion-icon>
                    <ion-input
                      v-model="user.name"
                      autocorrect="on"
                      autofocus="on"
                      color="primary"
                      inputmode="text"
                      placeholder="Enter your name"
                      required="true"
                    ></ion-input>
                  </ion-item>
                  <ion-item>
                    <ion-label color="primary" position="floating">Cognome</ion-label>
                    <ion-icon
                      color="primary"
                      :icon="personOutline"
                      slot="start"
                      class="ion-align-self-center"
                      size="small"
                    >
                    </ion-icon>
                    <ion-input
                      v-model="user.surname"
                      autocorrect="on"
                      autofocus="on"
                      color="primary"
                      inputmode="text"
                      placeholder="Enter your surname"
                      required="true"
                    ></ion-input>
                  </ion-item>
                  <ion-item>
                    <ion-label color="primary" position="floating">Email</ion-label>
                    <ion-icon
                      color="primary"
                      :icon="mailOutline"
                      slot="start"
                      class="ion-align-self-center"
                      size="small"
                    >
                    </ion-icon>
                    <ion-input
                      v-model="user.email"
                      autocorrect="on"
                      autofocus="on"
                      color="primary"
                      inputmode="email"
                      type="email"
                      placeholder="Enter your email"
                      required="true"
                    ></ion-input>
                  </ion-item>
                  <ion-item>
                    <ion-label color="primary" position="floating">Password</ion-label>
                    <ion-icon
                      color="primary"
                      :icon="lockClosedOutline"
                      slot="start"
                      class="ion-align-self-center"
                      size="small"
                    >
                    </ion-icon>
                    <ion-input
                      v-model="user.password"
                      :type="showPsw ? 'text' : 'password'"
                      autofocus="on"
                      color="primary"
                      required="true"
                    ></ion-input>
                    <ion-button
                      v-if="user.password"
                      slot="end"
                      class="ion-align-self-end togglePsw"
                      fill="outline"
                      @click="showPassword"
                    >
                      <ion-icon
                        :icon="showPsw ? eyeOutline : eyeOffOutline"
                        size="small"
                      ></ion-icon>
                    </ion-button>
                  </ion-item>
                  <password-meter
                    :password="user.password"
                    @score="onScore"
                    :class="score == 5 ? 'perfect' : ''"
                  />
                  <ion-row>
                    <ion-col>
                      <ion-button
                        :disabled="score <= 4"
                        type="submit"
                        class="ion-margin-vertical"
                        expand="full"
                        >INVIA RICHIESTA</ion-button
                      >
                    </ion-col>
                  </ion-row>
                </form>
              </ion-list>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="ion-margin-vertical ion-text-center ion-text-underlined">
              <h6 @click="() => router.push('/login')" class="underline cursor_pointer">
                ACCEDI
              </h6>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="ion-margin-vertical ion-text-center ion-text-underlined">
              <h6 @click="AzureLogin()" class="underline cursor_pointer">
                TEST LOGIN
              </h6>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="ion-margin-vertical ion-text-center ion-text-underlined">
              <h6 @click="AzureLogout()" class="underline cursor_pointer">
                TEST LOGOUT
              </h6>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="ion-margin-vertical ion-text-center ion-text-underlined">
              <h6 @click="AzureGetAccount()" class="underline cursor_pointer">
                TEST GET ACCOUNT
              </h6>
            </ion-col>
          </ion-row>
        </div>
      </ion-grid>
    </ion-content>
    <ion-toast
      :is-open="isOpenMsg"
      :message="registerMsg"
      :duration="1500"
      @didDismiss="setOpen(false)"
    ></ion-toast>
  </ion-page>
</template>

<script>

// import {AuthError} from "@azure/msal-browser";
import { useAuth } from "../config/useAuth";
// import { myMSALObj, state } from "../config/msalConfig";

const {login, logout, handleRedirect, getAccount} = useAuth();


import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonInput,
  IonButton,
  IonImg,
  IonText,
  IonLabel,
  IonItem,
  IonToast,
  IonList,
} from "@ionic/vue";
import {
  personOutline,
  lockClosedOutline,
  mailOutline,
  eyeOffOutline,
  eyeOutline,
  cogOutline,
} from "ionicons/icons";
import { useStore } from "vuex";
import { mapGetters } from "vuex";
import { useRouter } from "vue-router";
import axios from "axios";
import passwordMeter from "vue-simple-password-meter";
import { myMSALObj } from "@/config/msalConfig";

export default {
  name: "RegisterPage",
  components: {
    IonPage,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonIcon,
    IonInput,
    IonButton,
    IonImg,
    IonText,
    IonLabel,
    IonItem,
    IonToast,
    passwordMeter,
    IonList,
  },
 setup() {

    const router = useRouter();

    const handleLogin = async () =>{
    await login();  
    }
    
    const handleLogout = async () =>{
    await logout();  
    }
    
    const handleGetAccount = async () =>{
    await getAccount();  
    }

    const initialize = async () =>{
      try{
        await myMSALObj.initialize(); // call init
      }catch(error){
        //disable sso button
        console.log('init error',error);
      }
    }


    return {
      router,
      personOutline,
      lockClosedOutline,
      eyeOffOutline,
      eyeOutline,
      mailOutline,
      cogOutline,
      initialize,
      handleLogin,
      handleLogout,
      handleGetAccount
    };
  },
  data: () => ({
    loader: false,
    store: useStore(),
    user: {
      name: null,
      surname: null,
      email: null,
      password: null,
    },
    score: null,
    isOpenMsg: false,
    registerMsg: "",
    showPsw: false,
  }),
  async mounted() {

    await this.initialize();

  },
  computed: {
    ...mapGetters({
      getUser: "user",
    }),
  },
  methods: {
    setOpen(val) {
      this.isOpenMsg = val;
    },

    showPassword() {
      this.showPsw = !this.showPsw;
    },
    async AzureLogin(){
      
      await this.handleLogin(); 
    },
    async AzureLogout(){
      await this.handleLogout(); 
    },
    async AzureGetAccount(){
      this.handleGetAccount(); 
    },
    register() {
      this.showPsw = false;
      this.loader = true;
      var self = this;

      axios
        .post(`${process.env.VUE_APP_AUTH_URL}/signin`, this.user)
        .then((res) => {
          self.loader = false;
          let chk = res.data.id;
          if (chk) this.$router.push("/thank-you");
        })
        .catch(function (error) {
          self.loader = false;
          let message_err = error.response.data.message;
          let generic_err = error.message;

          message_err
            ? (self.registerMsg = message_err)
            : (self.registerMsg = generic_err);

          self.isOpenMsg = true;
        });
    },
    onScore({ score, strength }) {
      //modifying score and strenght to add special characters
      var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      var test = format.test(this.user.password);
      let supercheck = test ? 1 : 0;
      score = score + supercheck;
      strength = score == 5 ? "perfect" : strength;
      console.log(score); // from 0 to 4
      console.log(strength); // one of : 'risky', 'guessable', 'weak', 'safe' , 'secure' , 'perfect'
      this.score = score;
    },
  },
};
</script>

<style lang="scss">
.underline {
  text-decoration: underline;
}

.login_bg_mask {
  /* width: 400px;  */
  height: 300px !important;
}

.login_bg {
  padding: 20px;
  height: 100% !important;
  background-color: black;
  background-size: cover;
  background-position: center;
  background-size: cover;
  transition: all 0.5s;
}

.login_des {
  padding: 0 50px;
}

.togglePsw {
  --border-style: none;
  margin-left: 5px;
  // --border-radius: 0;
}

.po-password-strength-bar.secure {
  background-color: #b2e34b;
  width: 85%;
}

.po-password-strength-bar.perfect {
  width: 100%;
  background-color: #10cd10;
}

.cursor_pointer {
  cursor: pointer;
}
</style>
