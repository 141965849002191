<template>
  <ion-page>
    <ion-content class="login_container">
      <div class="login_bg_mask">
        <div class="login_bg" style="background-image: url('/assets/images/banner_login.jpg')">
          <ion-img src="/assets/images/ab_logo.svg" style="width: 100px"></ion-img>
        </div>
      </div>
      <ion-grid>
        <ion-row>
          <ion-col>
            <ion-text color="primary" class="ion-margin-horizontal ion-text-center">
              <h6 class="login_des">Inserire i dati di login {{ this.user.name }}</h6>
            </ion-text>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-progress-bar v-if="loader" type="indeterminate"></ion-progress-bar>
            <ion-list>
              <form name="ab_login" @submit.prevent="login">
                <ion-item>
                  <ion-label color="primary" position="floating">Username</ion-label>
                  <ion-icon color="primary" :icon="personOutline" slot="start" class="ion-align-self-center"
                    size="small">
                  </ion-icon>
                  <ion-input v-model="user.email" autocorrect="on" autocomplete="on" autofocus="on" color="primary"
                    inputmode="text" placeholder="Enter your email" required="true"></ion-input>
                </ion-item>
                <ion-item>
                  <ion-label color="primary" position="floating">Password</ion-label>
                  <ion-icon color="primary" :icon="lockClosedOutline" slot="start" class="ion-align-self-center"
                    size="small">
                  </ion-icon>
                  <ion-input v-model="user.password" :type="showPsw ? 'text' : 'password'" autofocus="on"
                    autocomplete="on" color="primary" placeholder="Enter your name" required="true"></ion-input>
                  <ion-button slot="end" class="ion-align-self-end togglePsw" fill="outline" @click="showPassword">
                    <ion-icon :icon="showPsw ? eyeOutline : eyeOffOutline" v-if="user.password" size="small"></ion-icon>
                  </ion-button>
                </ion-item>
                <ion-item>
                  <ion-checkbox v-model="remember_me"></ion-checkbox>
                  <p class="my_pl">Ricorda</p>
                </ion-item>
                <ion-row>
                  <ion-col>
                    <ion-button type="submit" class="ion-margin-vertical" expand="full">ACCEDI</ion-button>
                  </ion-col>
                </ion-row>

                <ion-row>
                  <ion-col class="ion-text-center">
                    <h6>OPPURE</h6>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col class="ion-text-center">
                    <h6 @click="() => router.push('/resetpassword')" class="underline cursor_pointer">
                      RESET PASSWORD
                    </h6>
                  </ion-col>
                </ion-row>

                <ion-row>
                  <ion-col>
                    <!-- <GoogleLogin></GoogleLogin> -->
                  </ion-col>
                </ion-row>
              </form>
            </ion-list>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="ion-margin-vertical ion-text-center ion-text-underlined">
            <h6 @click="() => router.push('/register')" class="underline cursor_pointer">
              RICHIEDI ACCESSO
            </h6>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="ion-margin-vertical ion-text-center ion-text-underlined">
            <h6 @click="AzureLogin()" class="underline cursor_pointer">
              TEST LOGIN
            </h6>
          </ion-col>
        </ion-row>
      </ion-grid>

      <!--ALERT POP UP-->
      <ion-alert :is-open="isOpenRef" :header="error_header" :sub-header="error_sub" :message="error_text"
        :buttons="['OK']" @didDismiss="setOpen(false)"></ion-alert>
      <!--ALERT POP UP-->
    </ion-content>
  </ion-page>
</template>

<script>
import { useAuth } from "../config/useAuth";
// import { myMSALObj, state } from "../config/msalConfig";

const { login, acquireToken } = useAuth();
import { myMSALObj } from "@/config/msalConfig";
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonInput,
  IonButton,
  IonImg,
  IonText,
  IonLabel,
  IonAlert,
  IonProgressBar,
  IonList,
  IonItem,
  IonCheckbox,
} from "@ionic/vue";
import { ref } from "vue";
import {
  personOutline,
  lockClosedOutline,
  eyeOffOutline,
  eyeOutline,
} from "ionicons/icons";
import { useStore } from "vuex";
import { mapGetters, mapState } from "vuex";
import { useRouter } from "vue-router";
import axios from "axios";
// import { authService } from "@/api";

export default {
  name: "LoginPage",
  components: {
    IonPage,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonIcon,
    IonInput,
    IonButton,
    IonImg,
    IonText,
    IonLabel,
    IonAlert,
    IonProgressBar,
    // GoogleLogin,
    IonList,
    IonItem,
    IonCheckbox,
  },
  setup() {
    const router = useRouter();
    const isOpenRef = ref(false);
    const setOpen = (state) => (isOpenRef.value = state);

    const initialize = async () => {
      try {
        await myMSALObj.initialize(); // call init

      } catch (error) {
        //disable sso button
        console.log('init error', error);
      }
    }

    const handleLogin = async () => {
      await login().then((res) => { console.log('res:' + res) });
    }

    const acquireToken = async () => {
      await acquireToken().then((res) => { console.log('res:' + res) });
    }

    return {
      router,
      personOutline,
      lockClosedOutline,
      eyeOffOutline,
      eyeOutline,
      isOpenRef,
      setOpen,
      initialize,
      handleLogin,
      acquireToken
    };


  },
  async mounted() {

    await this.initialize().then((res) => {
      // acquireToken().then((res)=>{console.log('res:'+res)});
      console.log('res:' + res);
    });

    // await acquireToken().then((res)=>{console.log('test')});
    console.log('test');
  },
  data: () => ({
    loader: false,
    remember_me: false,
    store: useStore(),
    user: {
      email: null,
      password: null,
    },
    error_header: "Attenzione",
    error_sub: "",
    error_text: "",
    showPsw: false,
  }),
  computed: {
    ...mapState(["user"]),

    ...mapGetters({
      getUser: "user",
    }),
  },
  methods: {
    showPassword() {
      this.showPsw = !this.showPsw;
    },

    showMsg(msg) {
      this.error_text = msg;
      this.setOpen(true);
    },
    async AzureLogin() {

      await this.handleLogin().then((res) => { console.log('res:' + res) });
    },
    login() {
      this.loader = true;
      this.showPsw = false;
      var self = this;
      axios
        .post(`${process.env.VUE_APP_AUTH_URL}/login`, this.user)
        .then((res) => {
          let token = res.data.token;

          if (token) {
            localStorage.setItem("access_token", token);
            this.store.commit("setToken", { token: token });

            let config = {
              headers: {
                token: token,
              },
              withCredentials: true,
            };

            axios
              .get(`${process.env.VUE_APP_AUTH_URL}/me`, config)
              .then((res) => {
                let authorized = res.data.authorized;
                let logged_in = res.data.logged_in;
                res.data.remember = this.remember_me;
                if (authorized) {
                  this.store.commit("setUser", {
                    state: authorized,
                    user: res.data,
                  });

                  self.loader = false;

                  if (this.getUser != {}) {

                    this.$router.push("/sedi");

                  }
                } else {
                  if (logged_in && !authorized) {


                    // self.loader = false;
                    // self.error_text = `La tua richiesta di registrazione è stata presa in carico. Riceverai conferma dell'abilitazione all'indirizzo ${res.data.email}`;

                    this.$router.push("/thank-you");

                  } else {
                    self.loader = false;
                    self.error_text = "ops, qualcosa è andato storto";
                  }

                  //generic error
                  self.loader = false;
                  self.setOpen(true);
                }
              })
              .catch(function (error) {
                //generic error
                self.loader = false;
                self.error_text = "ops, qualcosa è andato storto";
                self.setOpen(true);
                console.log(error.toJSON().status);
              });
          }
        })
        .catch(function (error) {
          // let status = error.toJSON().status;
          // switch(status){
          //   case 404: self.error_text = 'Utente non trovato';
          //   break;
          //   case 401: self.error_text = 'Email o password mancante';
          //   break;
          //   default : self.error_text = 'ops, qualcosa è andato storto';
          // }
          self.loader = false;
          let message_err = error.response.data.message;
          let generic_err = error.message;

          message_err ? (self.error_text = message_err) : (self.error_text = generic_err);

          self.isOpenMsg = true;

          self.setOpen(true);
        });
    },
  },

};
</script>

<style lang="scss">
.underline {
  text-decoration: underline;
}

.login_bg_mask {
  /* width: 400px;  */
  height: 300px !important;
}

.login_bg {
  padding: 20px;
  height: 100% !important;
  background-color: black;
  background-size: cover;
  background-position: center;
  background-size: cover;
  transition: all 0.5s;
}

.login_des {
  padding: 0 50px;
}

.togglePsw {
  --border-style: none;
  margin-left: 5px;
  // --border-radius: 0;
}

.cursor_pointer {
  cursor: pointer;
}

.my_pl {
  padding-left: 1rem;
}
</style>
