import { createRouter, createWebHistory } from "@ionic/vue-router";
import SedePage from "../views/SedePage.vue";
import LoginPage from "../views/LoginPage.vue";
import B2CPage from "../views/B2CPage.vue";
import SediPage from "../views/SediPage.vue";
import MainContainer from "../views/MainContainer.vue";
import RegisterPage from "../views/RegisterPage.vue";
import ThankYouRegisterPage from "../views/ThankYouRegisterPage.vue";
import ResetPassword from "@/views/ResetPassword.vue";
import NewPassword from "@/views/NewPassword.vue";
/*Vuex State management */
import store from "../store/modules/ab";

const routes = [
  {
    path: "/",
    component: B2CPage,
  },
  {
    path: "/login",
    name:'login',
    component: B2CPage,
  },
  {
    path: "/logout",
    name:'logout',
    beforeEnter: (to, from, next) => {
      store.commit('logoutUserState');
      next('/');
    }
  },
  {
    path: "/resetpassword",
    component: ResetPassword,
  },
  {
    path: "/newpassword",
    component: NewPassword,
  },
  {
    path: "/register",
    component: RegisterPage,
  },
  {
    path: "/thank-you",
    component: ThankYouRegisterPage,
  },
  {
    path: "/sedi",
    name: "sedi",
    component: MainContainer,
    children: [
      {
        path: "/sedi",
        name: "sedi",
        component: SediPage,
      },
      {
        path: "/sedi/:sede",
        name: "sede",
        component: SedePage,
        children: [
          {
            path: "",
            name: "",
            redirect: "/motori",
          },
          {
            path: "/motori",
            name: "motori",
            component: () => import("@/views/MotoriPage.vue"),
          },
          {
            path: "/motore",
            name: "motore",
            component: () => import("@/views/MotorePage.vue"),
            children: [
              {
                path: "",
                redirect: "/motori/motore",
              },
              {
                path: "/motori/motore",
                name: "motore",
                component: () => import("@/views/MotoreInfo.vue"),
              },
              {
                path: "/motori/analisi",
                name: "analisi",
                component: () => import("@/views/AnalisiPage.vue"),
              },
              {
                path: "/motori/upload",
                name: "upload",
                component: () => import("@/views/UploadAnalisiPage.vue"),
              },
              {
                path: "/motori/prelievo",
                name: "prelievo",
                component: () => import("@/views/PrelievoPage.vue"),
              },
            ],
          },
          {
            path: "/interventi",
            name: "interventi",
            component: () => import("@/views/InterventiPage.vue"),
          },
          {
            path: "/documenti",
            name: "documenti",
            component: () => import("@/views/RapportiPage.vue"),
          },
          {
            path: "/contatti",
            name: "contatti",
            component: () => import("@/views/ContattiPage.vue"),
          },
        ],

        // beforeEnter: (to) => {
        //   // reject the navigation
        //   const authenticated = store.getters['isLoggedIn'];
        //   if (!authenticated && to.name !== 'LoginPage') {
        //     return { name: 'Login' }
        //     // console.log('not auth')
        //   }
        //   console.log(to)
        // },

      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to, from) => {

  let access_token = localStorage.getItem('access_token');
  if(!access_token && to.name != 'login'){
    return '/login'
  }


  //   let check_remember = store.getters['user'].remember
  //   console.log(`route name = ${to.name}`);
  //   if(check_remember == true && (to.name == 'login' || to.name == 'logout' || to.name == 'undefined')){
  //       return '/sedi'
  //   }

  // return true
})

export default router;
